/* @import url('../../../public/css/theme.css'); */
.App {
  font-family: sans-serif;
  text-align: center;
}

.swiper-slide {
  width: 100%;
  height: 80vh !important;
}

.bg {
  width: 100%;
  height: inherit;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}
.i1{
  height: 70px;
}
.i3{
  width: 100%;
  display: flex;
}
.i4{
  margin-top: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  transition:    5s;
  animation-duration: 5s;
  /* height: 150px; */
  background-color: #2c7be5;
  /* background-color: #3ee52c; */
}
.i7 {
  margin: auto;
}
.i5{
  z-index: 300;
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.i6{
  margin-top: -12px;
  /* border-radius: 0px; */
}
.i8{
  width: auto;
  height: fit-content;
}

.i17{
  display: flex ;
  justify-content: space-between;
  /* border: 1px solid black; */
  flex-wrap: wrap;
  align-items: center;
}
.i16{
  width: 35%;
  height: 500px;
  /* border: 1px solid black; */
  padding-bottom: 20px;
  min-width: 350px;
  max-height: 500px;
  margin-right: 15px;
}
.i19{
  
  width: 50%;
  /* min-width: 300px; */
  max-width: 410px;
}
.i20{
  min-width: 350px;
  margin-right: -10px;
}
@media (max-width: 990px) {
  .i16{
    width: 100%;
    margin-bottom: 10px;
    
  }
  .i19{
    width: 100%;
    /* width: 300px; */
  }
    
}
.i21{
  /* border: 1px solid black; */
  min-width: 250px;
  max-width:  250px;
  margin-left: 20px;
  margin-right: 20px;
}
.i22{
  /* height: 500px; */
  /* border: 1px solid black; */
  /* position: fixed; */
  z-index: 200;
  /* margin-top: 17px; */
  /* margin-top: ; */
  /* opacity: 0; Initially hide the component */
  transition:    5s;
  font-size: 20px;
  /* background-color: white; */
}
.i23{
  width: 100%;
}
.i25{
  font-family: "Montserrat", sans-serif;
  
  font-size: 20px;
  /* width: none; */
  color: white;
}
.i26{
  display: flex;
  flex-direction:column;
  padding-right: 50px;
}
.i27{

  font-size: 26px;

  
}
.oo{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 80vw !important;
  left: 0;
  right: 0;
}
.i28{
  padding-top: 3px;
  font-size: 18px;
  /* text-decoration: none; */
  /* padding-top: 3px; */
}
.p2{
  margin-top: 50px;
  position: relative;
}
.p1{
  width: 100%;
  position: relative;
  visibility: hidden;
  position: absolute;
  padding: 30px 30px;
  background-color: #e5ebf4;
}
.LLLL{
  margin-bottom: 10px;
  height: 20px;
}
.lol:hover{

  background-color: #215fb0;
}
.lol{
  margin-top: 10px;
  width: 100%;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #2c7be5;
  color: white;
  border-radius: 2px;
}
.box{
  margin-top: 10px;
  width: 90%;
  /* border: 1px solid black; */
  height: 150px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}
.box a{
font-size: 15px;

}
.o78{
  display: flex;
  margin-top: 10px;
  height: 30px;
  text-align: center;
  /* border: 1px solid black; */
  margin: auto;
}
.p8787{
  /* margin-top: 10px; */
  /* margin: auto; */
 
}
.o74{
  font-size: 12px;
}
.p91{
  /* position: relative; */
  visibility: visible;
}

@media screen and (max-width: 590px) {
  .p91{
    position: absolute;
    visibility:hidden;
    top: -30px;
    z-index: 1;
  }
  .p1{
    z-index: 100;
    margin-top: -120px;
    position: relative;
    visibility: visible;
  }
}

 .slick-slide {
      margin: 0 27px;
  }

  /* the parent */
  .slick-list {
      margin: 0 -27px;
  }